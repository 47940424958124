import React, { useState, useEffect } from 'react';

import { Row, Col, Container } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';

const windowGlobal = typeof window !== 'undefined' && window;

let scrollPosition = 0;
const AboutPage = () => {
  const [activeHead, setActiveHead] = useState(0);

  const image = useStaticQuery(graphql`
    query {
      theCompany: file(relativePath: { eq: "theCompany.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cardImage1: file(relativePath: { eq: "rashin.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cardImage2: file(relativePath: { eq: "suma.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cardImage3: file(relativePath: { eq: "pushpa.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const scrollContent = [
    {
      id: 'content1',
      sNo: '01',
      title: 'Problem solvers',
      description: 'What color do you like? What layout do you want? No! we will ask you “What problem do you want to solve?”',
    },
    {
      id: 'content2',
      sNo: '02',
      title: 'Conversion oriented',
      description: 'Our web design strategy is built around user behaviour patterns, which maximises the potential of user conversion.',
    },
    {
      id: 'content3',
      sNo: '03',
      title: 'Quality conscious',
      description: 'Everything big are made out of small things. We take care of the small.',
    },
    {
      id: 'content4',
      sNo: '04',
      title: 'Up-to-date',
      description: 'State of the art digital products that are current and up-to-date with the latest trend and technology.',
    },
    {
      id: 'content5',
      sNo: '05',
      title: 'Partner',
      description: 'We don’t limit ourselves to just designing and developing digital products. Our consultants strive to add value to your business like how a partner would do.',
    },
    {
      id: 'content6',
      sNo: '06',
      title: 'On time support',
      description: 'We provide strong support for all our projects and services at a quick turnaround time.',
    },
  ];

  const handleScroll = () => {
    scrollPosition = windowGlobal.scrollY;
    // const content1 = document.getElementById('content1');
    const content2 = document.getElementById('content2');
    const content3 = document.getElementById('content3');
    const content4 = document.getElementById('content4');
    const content5 = document.getElementById('content5');
    const content6 = document.getElementById('content6');

    if (scrollPosition - 300 < content2.offsetTop + 350) {
      setActiveHead(0);
    } else if (scrollPosition - 300 >= content2.offsetTop && scrollPosition - 300 < content3.offsetTop + 350) {
      setActiveHead(1);
    } else if (scrollPosition - 300 >= content3.offsetTop && scrollPosition - 300 < content4.offsetTop + 350) {
      setActiveHead(2);
    } else if (scrollPosition - 300 >= content4.offsetTop && scrollPosition - 300 < content5.offsetTop + 350) {
      setActiveHead(3);
    } else if (scrollPosition - 300 >= content5.offsetTop && scrollPosition - 300 < content6.offsetTop + 350) {
      setActiveHead(4);
    } else if (scrollPosition - 300 >= content6.offsetTop + 350) {
      setActiveHead(5);
    }
  };

  useEffect(() => {
    windowGlobal.addEventListener('scroll', handleScroll);
    return () => windowGlobal.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Layout pageInfo={{ pageName: 'about' }} title="When passion meets skill" description="We are always excited to build digital solutions that help simplify and automate business processes.">
      <SEO title="About Us" />
      <Row className="flex-column-reverse flex-md-row mb-3 d-flex align-items-stretch" noGutters style={{ position: 'relative' }}>
        <Col xs={12} md={7} lg={6} className="py-5">
          <div className="the-company the-company-cext text-white" style={{ backgroundColor: '#222222', flexDirection: 'column' }}>
            <h2 className="text-left">The Company</h2>
            <h6 className="pt-2">
              7th Pillar was established in 2014, based out of Cochin, a fast developing IT city in South of India. We have had the opportunity to work for several clients from across the globe on exciting projects. This experience helped us understand businesses better, hence has improved our ability to propose ideal tech solutions. We have a skilled team of designers and developers who can help
              you build your vision into product.
            </h6>
          </div>
        </Col>
        <Col xs={12} md={5} lg={6} className="d-flex align-items-center">
          <div className="w-100">
            <Img fluid={image.theCompany.childImageSharp.fluid} />
          </div>
        </Col>
      </Row>

      <Row noGutters className="mx-0 p-0">
        <Col xs={12} md={4} className="why-choose-us ">
          <div id="why" className=" text">
            <h1>
              Why
              <br />
              Choose us?
            </h1>
          </div>
          <div className="border border-left-0 border-dark d-none d-md-block why-choose-us-line" />
        </Col>
        <Col xs={12} md={8}>
          <div className="scroll-content w-100" style={{ minHeight: '100vh', backgroundColor: '#222222' }}>
            {scrollContent.map((item, index) => (
              <Row id={item.id} key={item.id} noGutters className={`text-white ${activeHead === index ? 'opacity-active-head' : 'opacity-head'}`}>
                <h1 className="no-line-height font-weight-bold">{item.sNo}&nbsp;&nbsp;&nbsp;&nbsp;</h1>
                <Col>
                  <div style={{ width: 450, maxWidth: '100%' }}>
                    <h2>{item.title}</h2>
                    <p className="pb-3">{item.description}</p>
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        </Col>
      </Row>
      <Row noGutters className="the-team">
        <div style={{ backgroundColor: '#FFFFFF', zIndex: 10 }}>
          <h1>The Team</h1>
        </div>
      </Row>
      <Container className="my-4">
        <div className="card-columns">
          <div className="card team-card">
            <Img fluid={image.cardImage1.childImageSharp.fluid} style={{ position: 'relative' }} />
            <div className="card-body teamcard-Title">
              <h6 className="font-weight-bold card-title my-1 pt-2" style={{ color: '#FFFFFF' }}>
                Rashin Pothan
              </h6>
              <p className="card-text" style={{ color: '#FFFFFF' }}>
                Founder, CEO
              </p>
              <p className="card-text mt-2 py-3" style={{ color: '#FFFFFF' }}>
                Rashin founded 7th Pillar in 2014, out of his passion for building digital products for businesses. He has been involved in several business ventures in fields like Real estate, trading &amp; healthcare. The experience he gained out of these helps him to understand problems that businesses face and suggest appropriate technological solutions.
              </p>
            </div>
          </div>
          <div className="card team-card">
            <Img fluid={image.cardImage2.childImageSharp.fluid} style={{ position: 'relative' }} />
            <div className="card-body teamcard-Title">
              <h6 className="font-weight-bold card-title my-1 pt-2" style={{ color: '#FFFFFF' }}>
                Suma Johny
              </h6>
              <p className="card-text " style={{ color: '#FFFFFF' }}>
                COO
              </p>
              <p className="card-text mt-2 py-3" style={{ color: '#FFFFFF' }}>
                Suma, graduated as a Biotechnology major, however found her passion in administration and operations. She takes care of the daily operations at 7th Pillar and makes sure company&apos;s strategies are well executed and the joint vision is achieved
              </p>
            </div>
          </div>
          <div className="card team-card">
            <Img fluid={image.cardImage3.childImageSharp.fluid} style={{ position: 'relative' }} />
            <div className="card-body teamcard-Title">
              <h6 className="font-weight-bold card-title my-1 pt-2" style={{ color: '#FFFFFF' }}>
                Pushpa Kariyadan
              </h6>
              <p className="card-text" style={{ color: '#FFFFFF' }}>
                CFO
              </p>
              <p className="card-text mt-2 py-3" style={{ color: '#FFFFFF' }}>
                With over 30 years experience in financial management of several companies, she helps us manage our cash flow and formulate financial strategies to make sure our company is financially healthy.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default AboutPage;
